import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import ym from "react-yandex-metrika"

class Company extends React.Component {

  componentDidMount() {
    ym("hit", "/company")
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Компания - Исполиния</title>
          <meta name="description"
                content="ИСПОЛИНИЯ - официальный дилер оригинальной продукции RBM на российском рынке и единственный из всех партнеров именитого итальянского производителя, кто предлагает исключительно продукцию данного бренда." />

          <meta property="og:title" content="Компания" />
          <meta property="og:description"
                content="ИСПОЛИНИЯ - официальный дилер оригинальной продукции RBM на российском рынке и единственный из всех партнеров именитого итальянского производителя, кто предлагает исключительно продукцию данного бренда." />

        </Helmet>

        <Header />
        <main>
          <Container className="mb-5">
            <div className="row m-auto">
              <StaticImage className="col-lg m-auto wrap-image"
                           src="../images/company.jpg"
                           placeholder="blurred"
                           alt="Фотография генерального директора исполинии с представителями RBM"
                           style={{ maxWidth: "600px" }}
              />
              <div className="col-lg">
                <p><span className="red">ИСПОЛИНИЯ</span> — официальный дилер оригинальной продукции RBM на российском рынке и
                  единственный из всех
                  партнеров именитого итальянского производителя, кто предлагает исключительно продукцию данного бренда.</p>
                <p>Есть мнение, что такой подход ограничит возможности нашего развития. Мы же уверены: подобная концентрация
                  благоприятно скажется на уровне профессиональных компетенций. Это поможет быстрее раскрыть высокий потенциал
                  всего широкого спектра продукции RBM на рынке инженерных систем России. Ничто нас не отвлекает от
                  главного.</p>
              </div>


            </div>
            <p><span className="red">ИСПОЛИНИ</span><span className="blue">Я-RBM</span></p>
            <p>Для нас информация "О компании" — это не только факты об особенностях организации. Прежде всего на ум приходит
              понятие "компания друзей", приятное сообщество с нашими единомышленниками. Такой формат сотрудничества придает
              уверенности в успешном выполнении любой поставленной задачи. И мы готовы справляться с каждым новым вызовом с
              удовольствием.
              Занимаясь одним единственным брендом, ИСПОЛИНИЯ уже сейчас ведет ту деятельность, которая позволяет представлять
              интересы RBM в России по всем возникающим вопросам.
            </p>
            <p><span className="blue">НАША ЦЕЛЬ</span> – сделать весь производимый компанией RBM ассортимент доступнее для
              широкого круга профессионалов
              российского инженерного рынка, обеспечить высокий уровень информационной и технической поддержки и кратчайшие
              сроки поставки всех необходимых компонентов.
            </p>
          </Container>

        </main>
        <Footer />

      </div>
    )
  }
}
export default Company